<template>
  <b-card class="chartCard" no-body>
    <b-card-header>
      <div>
        <b-card-sub-title class="headerTitle">
          {{ title }}
        </b-card-sub-title>
      </div>
      <!-- datepicker -->
      <!-- datepicker -->
    </b-card-header>

    <!-- chart -->
    <b-card-body>
      <div class="operatorsList">
        <div class="item" v-for="(operator, index) in data.data.labels">
          <template v-if="operator !== 'Ruben Ordanyan'">
            <div class="label">{{operator}}</div>
            <div class="value">{{data.data.datasets[0].data[index]}}</div>
          </template>
        </div>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardBody, BCardHeader, BCardTitle, BCardSubTitle,
} from 'bootstrap-vue'
import HorizontalBarChart from '@/views/pages/statistics/Charts/components/HorizontalBarChart'

export default {
  components: {
    BCard,
    BCardBody,
    BCardHeader,
    BCardTitle,
    BCardSubTitle,
    HorizontalBarChart,
  },
  props: [
    'data', 'title', 'height'
  ],
  computed: {
    hasHeight () {
      return (this.height > 0)
    }
  },
  data() {
    return {
      rangePicker: ['2019-05-01', '2019-05-10'],
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.headerTitle {
  color: #000!important;
  font-weight: bold;
}

.operatorsList {
  display: flex;
  flex-direction: column;
  border: 1px solid #ccc;
  width: 30%;

  .item {
    display: flex;
    color: #000;
    font-weight: bold;
    font-size: 15px;
    border-bottom: 1px solid #ccc;

    &:last-child {
      border-bottom: none;
    }

    .label {
      border-right: 1px solid #ccc;
      width: 50%;
      padding: 5px 0;
      text-align: center;
    }

    .value {
      width: 50%;
      padding: 5px 0;
      text-align: center;
    }
  }
}
</style>
