import axios from '@axios'

export default {
  namespaced: true,
  state: {
    checkups: [0,0],
    companiesStat: [],
    operators: [],
    fuels: [],
    car_groups: [],
    year: []
  },
  getters: {
  },
  mutations: {
    SET_CHECKUPS(state, data) {
      state.checkups = data
    },
    SET_COMPANIES_STAT(state, data) {
      state.companiesStat = data
    },
    SET_OPERATORS(state, data) {
      state.operators = data
    },
    SET_FUELS(state, data) {
      state.fuels = data
    },
    SET_CAR_GROUPS(state, data) {
      state.car_groups = data
    },
    SET_YEAR_DATA(state, data) {
      state.year = data
    }
  },
  actions: {

    getCheckupsStatistics ({commit}, payload) {
      return new Promise((resolve, reject) => {
        axios.get(`statistics/checkups?${payload}`)
          .then(response => {
            commit('SET_CHECKUPS', response.data.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    getCompaniesCheckups ({commit}, payload) {
      return new Promise(((resolve, reject) => {
        axios.get(`statistics/companies-checkups?${payload}`)
          .then(response => {
            commit('SET_COMPANIES_STAT', response.data.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      }))
    },

    getOperatorsStat ({commit}, payload) {
      return new Promise(((resolve, reject) => {
        axios.get(`statistics/operators?${payload}`)
          .then(response => {
            commit('SET_OPERATORS', response.data.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      }))
    },

    getFuelStatistics ({commit}, payload) {
      return new Promise((resolve, reject) => {
        axios.get(`statistics/fuels?${payload}`)
          .then(response => {
            commit('SET_FUELS', response.data.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    getCarGroupStatistics ({commit}, payload) {
      return new Promise((resolve, reject) => {
        axios.get(`statistics/car-groups?${payload}`)
          .then(response => {
            commit('SET_CAR_GROUPS', response.data.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    getYearStatistics ({commit}, payload) {
      return new Promise((resolve, reject) => {
        axios.get(`statistics/year-checkups?${payload}`)
          .then(response => {
            commit('SET_YEAR_DATA', response.data.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    }

  }
}
