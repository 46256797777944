<template>
  <b-card class="pieChart chartCard" no-body>
    <b-card-header>
      <b-card-title>{{title}}</b-card-title>
    </b-card-header>

    <b-card-body class="d-flex">

      <!-- chart info -->
      <div v-if="list" class="pie-chart-content-data nice-scroll">
        <div
            v-for="(label,index) in data.chartOptions.labels"
            :key="index"
            class="d-flex justify-content-between pieChartListItem"
            :class="index === data.chartOptions.labels.length - 1 ? 'mb-0':'pieChartMargin'"
        >
          <div class="series-info d-flex align-items-center">
            <span class="font-weight-bolder ml-75 mr-25">{{ label }}</span><span>- {{ data.series[index] }}</span>
          </div>
        </div>
      </div>

      <!-- apex chart -->
      <vue-apex-charts
          type="donut"
          height="150"
          :options="data.chartOptions"
          :series="data.series"
      />

    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BDropdown, BDropdownItem, BCardBody,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BDropdown,
    BDropdownItem,
    BCardBody,
    VueApexCharts,
  },
  props: [
      'title',
      'data',
      'list'
  ],
  data() {
    return {

    }
  },
  created() {

  },
}
</script>

<style lang="scss">
.pieChart {
  color: #000;
  font-weight: bold;
  .card-title {
    width: 100%;
    text-align: center;
    color: #000;
    font-weight: bold;
  }
}
</style>
