<template>
  <b-card class="chartCard"  no-body>
    <b-card-header>
      <!-- title and subtitle -->
      <div>
        <b-card-title class="d-flex align-items-center">
          <b-dropdown
              split
              :text="current_year.toString()"
              variant="outline-success"
          >
            <b-dropdown-item @click="changeCurrentYear(year)" v-for="year in years_list">
              {{year}}
            </b-dropdown-item>
          </b-dropdown>
          <div class="ml-1">{{total_count}} տեխզննում</div>
        </b-card-title>
      </div>
    </b-card-header>

    <b-card-body>
      <vue-apex-charts
          type="line"
          height="400"
          :options="data.chartOptions"
          :series="data.series"
      />
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardBody, BCardHeader, BCardTitle, BCardSubTitle, BBadge, BDropdown, BDropdownItem
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'

export default {
  components: {
    VueApexCharts,
    BCardHeader,
    BCard,
    BBadge,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BDropdown,
    BDropdownItem
  },
  props: ['title', 'years_list', 'current_year', 'data'],
  data() {
    return {

    }
  },
  computed: {
    total_count () {
      let count = 0
      this.data.series[0].data.map((c) => {
        count += c
      })
      return count.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")
    }
  },
  methods: {
    changeCurrentYear (year) {
      this.$emit('year_changed', year)
    }
  }
}
</script>
