<template>
  <div id="analytics" v-if="$checkPermission(routeName, 'view')">
    <b-card-code no-body>
      <b-row>
        <b-col class="filtersContainer" md="12">
          <div class="filters">

            <div class="filter-column">
              <date-range-picker
                  ref="picker"
                  :opens="'center'"
                  v-model="dateRange"
                  :locale-data="localeData"
                  :ranges="dateRanges"
                  :always-show-calendars="false"
                  @update="updateRange"
              >
                <template v-slot:input="picker" style="min-width: 350px;">
                  <fa-icon class="header-icon calendarIcon" :icon="['far', 'calendar-alt']"/>
                  {{ selected_picker_value(picker) }}
                </template>
              </date-range-picker>
            </div>

            <div class="filter-column" v-if="userType === 'ADMIN' || userType === 'VIEWER'">
              <v-select
                  @input="changeCompany"
                  placeholder="Կազմակերպություն"
                  id="vue-select"
                  :reduce="prefix => prefix.id"
                  v-model="filters.company"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="companies"
                  class="companyFilterSelect"
              />

            </div>

          </div>
        </b-col>
      </b-row>
    </b-card-code>

    <b-row>

      <b-col v-if="years.length > 0" md="12">
        <LineChart :data="yearData" @year_changed="changeYear" :current_year="current_year" :years_list="years_list" title="Տարեկան վիճակագրություն"/>
      </b-col>

      <b-col md="4">
        <Pie :list="true" :data="checkupData" :title="selected_picker_value(dateRange)"/>
      </b-col>

      <b-col md="4">
        <Pie :list="true" :data="fuelsData" title="Վառելիքայի վիճակագրություն"/>
      </b-col>

      <b-col md="4">
        <Pie :list="true" :data="carGroupData" title="Մեքենայի խմբեր"/>
      </b-col>


    </b-row>
  </div>

</template>

<script>

// Components
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import { BRow, BCol } from 'bootstrap-vue'
import vSelect from 'vue-select'
import DateRangePicker from 'vue2-daterange-picker'

// Stores
import store from '@/store'
import techServiceModule from '@/store/tech-service/techServiceModule'
import companyModule from '@/store/companies/companyModule'
import carsModule from '@/store/cars/cars'
import statisticsModule from '@/store/statistics/statisticsModule'

// Charts
import Pie from '@/views/pages/statistics/Charts/Pie'
import Bar from '@/views/pages/statistics/Charts/Bar'
import LineChart from '@/views/pages/statistics/Charts/Line'
import Operators from '@/views/pages/statistics/Charts/Operators'

import { $themeColors } from '@themeConfig'
import useAppConfig from "@core/app-config/useAppConfig";
import {computed} from "@vue/composition-api/dist/vue-composition-api";
const chartColors = {
  primaryColorShade: '#836AF9',
  yellowColor: '#ffe800',
  successColorShade: '#28dac6',
  warningColorShade: '#ffe802',
  warningLightColor: '#FDAC34',
  infoColorShade: '#299AFF',
  greyColor: '#4F5D70',
  blueColor: '#2c9aff',
  blueLightColor: '#84D0FF',
  greyLightColor: '#EDF1F4',
  tooltipShadow: 'rgba(0, 0, 0, 0.25)',
  lineChartPrimary: '#666ee8',
  lineChartDanger: '#ff4961',
  labelColor: '#6e6b7b',
  grid_line_color: 'rgba(200, 200, 200, 0.2)',
}
let thisMonthStart = new Date(new Date().getFullYear(), new Date().getMonth(), 1)
let thisMonthEnd = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)

export default {
  name: 'statistics',
  components: {
    BCardCode,
    BRow,
    BCol,
    vSelect,
    Pie,
    Bar,
    Operators,
    LineChart,
    DateRangePicker,
  },
  setup() {
    const { skin } = useAppConfig()

    const isDark = computed(() => skin.value === 'dark')

    return {
      skin,
      isDark
    }
  },
  data() {
    return {
      TECH_SERVICE_STORE_MODULE_NAME: 'techServiceModule',
      COMPANIES_STORE_MODULE_NAME: 'companiesModule',
      CARS_STORE_MODULE_NAME: 'carsModule',
      STATISTICS_MODULE_NAME: 'statisticsModule',
      localeData: {
        applyLabel: 'Հաստատել',
        cancelLabel: 'Չեղարկել',
        ranges: [],
        firstDay: 1,
        format: 'dd/mm/yyyy',
        customRangeLabel: 'Այլ',
        daysOfWeek: ['Կիր', 'Երկ', 'Երք', 'Չոր', 'Հնգ', 'Ուրբ', 'Շբթ'],
        monthNames: ['Հուն', 'Փետ', 'Մար', 'Ապր', 'Մայ', 'Հուն', 'Հուլ', 'Օգ', 'Սեպ', 'Հոկ', 'Նոյ', 'Դեկ'],
        taxData: []
      },
      filters: {
        company: null
      },
      dateRange: {
        startDate: thisMonthStart,
        endDate: thisMonthEnd
      },
      current_year: new Date().getFullYear(),
    }
  },
  computed: {

    years () {
      return this.$store.state[this.STATISTICS_MODULE_NAME].year
    },

    yearData () {
      const data = {
        series: [
          {
            data: [],
          },
        ],
        chartOptions: {
          chart: {
            zoom: {
              enabled: false,
            },
            toolbar: {
              show: false,
            },
          },
          markers: {
            strokeWidth: 7,
            strokeOpacity: 1,
            strokeColors: [$themeColors.light],
            colors: ['#000'],
          },
          colors: ['#000'],
          dataLabels: {
            enabled: false,
          },
          stroke: {
            curve: 'straight',
          },
          grid: {
            xaxis: {
              lines: {
                show: true,
              },
            },
          },
          tooltip: {
            y: {
              formatter: undefined,
              title: {
                formatter: (seriesName) => '',
              },
            },
          },
          xaxis: {
            categories: [
              'Հունվար',
              'Փետրվար',
              'Մարտ',
              'Ապրիլ',
              'Մայիս',
              'Հունիս',
              'Հուլիս',
              'Օգոստոս',
              'Սեպտեմբեր',
              'Հոկտեմբեր',
              'Նոյեմբեր',
              'Դեկտեմբեր',
            ],
          },
          yaxis: {
            // opposite: isRtl,
          },
        },
      }

      data.series[0].data = []
      data.series[0].data.push(...this.years)

      return data
    },

    years_list () {
      const data = []
      for (let i = new Date().getFullYear(); i >= 2010; i--) {
        data.push(i)
      }
      return data
    },

    routeName () {
      if (this.userType === 'ADMIN') {
        return 'statistics'
      }
      else if (this.userType === 'VIEWER') {
        return 'all-statistics'
      }
      else if (this.userType === 'TECHNICAL_SERVICE_OWNER') {
        return 'companies-statistics'
      }
      else if (this.userType === 'TECHNICAL_SERVICE_OPERATOR') {
        return 'company-statistics'
      }
      return ''
    },

    userType() {
      return atob(this.$store.state.user_type)
    },

    selected_picker_value() {
      return (data) => {
        return this.$options.filters.formatDay(data.startDate) + ' - ' + this.$options.filters.formatDay(data.endDate)
      }
    },

    firstCheckupDate() {
      return this.$store.state[this.TECH_SERVICE_STORE_MODULE_NAME].firstCheckupDate
    },

    dateRanges() {
      // Today
      let today = new Date()

      let allStart = new Date(this.firstCheckupDate)

      let todayStart = new Date()
      let todayEnd = new Date()

      // Yesterday
      let yesterdayStart = new Date()
      yesterdayStart.setDate(today.getDate() - 1)
      let yesterdayEnd = new Date()
      yesterdayEnd.setDate(today.getDate() - 1)

      // This week
      let thisWeekStart = new Date(new Date().setDate(new Date().getDate() + 1 - new Date().getDay()))
      let thisWeekEnd = new Date(new Date().setDate(new Date().getDate() + 1 - new Date().getDay() + 6))

      // Last Week
      let lastWeekStart = new Date(new Date().setDate(new Date().getDate() - 6 - new Date().getDay()))
      let lastWeekEnd = new Date(new Date().setDate(new Date().getDate() - new Date().getDay()))

      // This Month
      let thisMonthStart = new Date(new Date().getFullYear(), new Date().getMonth(), 1)
      let thisMonthEnd = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)

      // Last Month
      let lastMonthStart = new Date(today.getFullYear(), today.getMonth() - 1, 1)
      let lastMonthEnd = new Date(today.getFullYear(), today.getMonth(), 0)

      // This Quarter
      let currentMonth = today.getMonth()
      let currentYear = today.getFullYear()
      let thisQuarterStart
      let thisQuarterEnd
      let lastQuarterStart
      let lastQuarterEnd
      if (currentMonth >= 0 && currentMonth < 3) {
        thisQuarterStart = new Date(today.getFullYear(), 0, 1)
        thisQuarterEnd = new Date(new Date().getFullYear(), 2, 31)

        lastQuarterStart = new Date(today.getFullYear() - 1, 9, 1)
        lastQuarterEnd = new Date(today.getFullYear() - 1, 11, 31)
      } else if (currentMonth >= 3 && currentMonth < 6) {
        thisQuarterStart = new Date(today.getFullYear(), 3, 1)
        thisQuarterEnd = new Date(new Date().getFullYear(), 5, 30)

        lastQuarterStart = new Date(today.getFullYear(), 0, 1)
        lastQuarterEnd = new Date(new Date().getFullYear(), 2, 31)
      } else if (currentMonth >= 6 && currentMonth < 9) {
        thisQuarterStart = new Date(today.getFullYear(), 6, 1)
        thisQuarterEnd = new Date(new Date().getFullYear(), 8, 30)

        lastQuarterStart = new Date(today.getFullYear(), 3, 1)
        lastQuarterEnd = new Date(new Date().getFullYear(), 5, 30)
      } else if (currentMonth >= 9 && currentMonth < 12) {
        thisQuarterStart = new Date(today.getFullYear(), 9, 1)
        thisQuarterEnd = new Date(new Date().getFullYear(), 11, 31)

        lastQuarterStart = new Date(today.getFullYear(), 3, 1)
        lastQuarterEnd = new Date(new Date().getFullYear(), 5, 30)
      }

      let thisSemesterStart
      let thisSemesterEnd
      let lastSemesterStart
      let lastSemesterEnd
      if (currentMonth >= 0 && currentMonth < 5) {
        thisSemesterStart = new Date(today.getFullYear(), 0, 1)
        thisSemesterEnd = new Date(new Date().getFullYear(), 5, 30)

        lastSemesterStart = new Date(today.getFullYear() - 1, 6, 1)
        lastSemesterEnd = new Date(today.getFullYear() - 1, 11, 31)

      } else {
        thisSemesterStart = new Date(today.getFullYear(), 6, 1)
        thisSemesterEnd = new Date(new Date().getFullYear(), 11, 31)

        lastSemesterStart = new Date(today.getFullYear(), 0, 1)
        lastSemesterEnd = new Date(today.getFullYear(), 5, 30)
      }

      // This Year
      let thisYearStart = new Date(new Date().getFullYear(), 0, 1)
      let thisYearEnd = new Date(new Date().getFullYear(), 11, 31)

      // Last Year
      let lastYearStart = new Date(new Date().getFullYear() - 1, 0, 1)
      let lastYearEnd = new Date(new Date().getFullYear() - 1, 11, 31)

      const data = {
        'Այսօր': [todayStart, todayEnd],
        'Երեկ': [yesterdayStart, yesterdayEnd],
        'Այս շաբաթ': [thisWeekStart, thisWeekEnd],
        'Անցած շաբաթ': [lastWeekStart, lastWeekEnd],
        'Այս ամիս': [thisMonthStart, thisMonthEnd],
        'Անցած ամիս': [lastMonthStart, lastMonthEnd],
        'Այս եռամսյակ': [thisQuarterStart, thisQuarterEnd],
        'Անցած եռամսյակ': [lastQuarterStart, lastQuarterEnd],
        'Այս կիսամյակ': [thisSemesterStart, thisSemesterEnd],
        'Անցած կիսամյակ': [lastSemesterStart, lastSemesterEnd],
        'Այս տարի': [thisYearStart, thisYearEnd],
        'Անցած տարի': [lastYearStart, lastYearEnd],
        'Բոլորը': [allStart, todayEnd],
      }

      if (this.userType === 'VIEWER') {
        delete data['Բոլորը']
      }

      return data
    },

    companies() {
      return [
        {
          label: 'Կազմակերպություն',
          value: null,
          disabled: true
        },
        ...this.$store.state[this.COMPANIES_STORE_MODULE_NAME].companies.map(e => ({
          label: e.name,
          id: e._id
        }))
      ]
    },

    checkupData() {
      return {
        series: this.$store.state[this.STATISTICS_MODULE_NAME].checkups,
        chartOptions: {
          labels: ['Սարքին', 'Անսարք'],
          colors: [$themeColors.success, $themeColors.danger],
          chart: {
            toolbar: {
              show: false,
            },
          },
          dataLabels: {
            enabled: true,
          },
          legend: { show: false },
          comparedResult: [2, -3, 8],
          stroke: { width: 0 },
        },
      }
    },

    fuelsData() {
      const data = {
        series: [],
        chartOptions: {
          labels: [],
          chart: {
            toolbar: {
              show: false,
            },
          },
          dataLabels: {
            enabled: true,
          },
          legend: { show: false },
          comparedResult: [2, -3, 8],
          stroke: { width: 0 },
        },
      }
      data.series = []
      data.chartOptions.labels = []
      const fuels = this.$store.state[this.STATISTICS_MODULE_NAME].fuels
      fuels.map((item) => {
        data.series.push(item.count)
        data.chartOptions.labels.push(item.name)
      })
      return data
    },

    carGroupData() {
      const data = {
        series: [],
        chartOptions: {
          labels: [],
          chart: {
            toolbar: {
              show: false,
            },
          },
          dataLabels: {
            enabled: true,
          },
          legend: { show: false },
          comparedResult: [2, -3, 8],
          stroke: { width: 0 },
        },
      }
      data.series = []
      data.chartOptions.labels = []
      const car_groups = this.$store.state[this.STATISTICS_MODULE_NAME].car_groups
      car_groups.map((item) => {
        data.series.push(item.count)
        data.chartOptions.labels.push(item.name)
      })
      return data
    },

    companiesList () {
      return this.$store.state[this.STATISTICS_MODULE_NAME].companiesStat
    },

    operators () {
      return this.$store.state[this.STATISTICS_MODULE_NAME].operators
    },

    companiesData() {
      const data = {
        options: {
          elements: {
            rectangle: {
              borderWidth: 2,
              borderSkipped: 'top',
            },
          },
          tooltips: {
            // Updated default tooltip UI
            shadowOffsetX: 1,
            shadowOffsetY: 1,
            shadowBlur: 8,
            shadowColor: chartColors.tooltipShadow,
            backgroundColor: $themeColors.light,
            titleFontColor: $themeColors.dark,
            bodyFontColor: $themeColors.dark,
          },
          responsive: true,
          maintainAspectRatio: false,
          responsiveAnimationDuration: 500,
          legend: {
            display: false,
          },
          scales: {
            xAxes: [
              {
                display: true,
                gridLines: {
                  zeroLineColor: chartColors.grid_line_color,
                  borderColor: 'transparent',
                  color: chartColors.grid_line_color,
                  drawTicks: false,
                },
                scaleLabel: {
                  display: true,
                },
                ticks: {
                  min: 0,
                  fontColor: chartColors.labelColor,
                },
              },
            ],
            yAxes: [
              {
                display: true,
                gridLines: {
                  display: false,
                },
                scaleLabel: {
                  display: true,
                },
                ticks: {
                  fontColor: chartColors.labelColor,
                },
              },
            ],
          },
        },
        data: {
          labels: [],
          datasets: [
            {
              data: [],
              backgroundColor: $themeColors.info,
              borderColor: 'transparent',
              barThickness: 15,
            },
          ],
        },
      }
      const companies = this.companiesList
      data.data.labels = []
      data.data.datasets[0].data = []
      companies.map(company => {
         data.data.labels.push(company.name)
         data.data.datasets[0].data.push(company.count)
      })

      return data
    },

    operatorData() {
      const data = {
        options: {
          elements: {
            rectangle: {
              borderWidth: 2,
              borderSkipped: 'top',
            },
          },
          tooltips: {
            // Updated default tooltip UI
            shadowOffsetX: 1,
            shadowOffsetY: 1,
            shadowBlur: 8,
            shadowColor: chartColors.tooltipShadow,
            backgroundColor: $themeColors.light,
            titleFontColor: $themeColors.dark,
            bodyFontColor: $themeColors.dark,
          },
          responsive: true,
          maintainAspectRatio: false,
          responsiveAnimationDuration: 500,
          legend: {
            display: false,
          },
          scales: {
            xAxes: [
              {
                display: true,
                gridLines: {
                  zeroLineColor: chartColors.grid_line_color,
                  borderColor: 'transparent',
                  color: chartColors.grid_line_color,
                  drawTicks: false,
                },
                scaleLabel: {
                  display: true,
                },
                ticks: {
                  min: 0,
                  fontColor: chartColors.labelColor,
                },
              },
            ],
            yAxes: [
              {
                display: true,
                gridLines: {
                  display: false,
                },
                scaleLabel: {
                  display: true,
                },
                ticks: {
                  fontColor: chartColors.labelColor,
                },
              },
            ],
          },
        },
        data: {
          labels: [],
          datasets: [
            {
              data: [],
              backgroundColor: $themeColors.info,
              borderColor: 'transparent',
              barThickness: 15,
            },
          ],
        },
      }
      const operators = this.operators
      data.data.labels = []
      data.data.datasets[0].data = []
      operators.map(company => {
         data.data.labels.push(company.name)
         data.data.datasets[0].data.push(company.count)
      })

      console.log(data)
      return data
    },

    data() {
      return {
        series: [58.6, 34.9, 6.5],
        chartOptions: {
          labels: ['Desktop', 'Mobile', 'Tablet'],
          colors: [$themeColors.success, $themeColors.warning, $themeColors.danger],
        },
      }
    }
  },
  created() {
    if (!store.hasModule(this.TECH_SERVICE_STORE_MODULE_NAME)) store.registerModule(this.TECH_SERVICE_STORE_MODULE_NAME, techServiceModule)
    if (!store.hasModule(this.COMPANIES_STORE_MODULE_NAME)) store.registerModule(this.COMPANIES_STORE_MODULE_NAME, companyModule)
    if (!store.hasModule(this.CARS_STORE_MODULE_NAME)) store.registerModule(this.CARS_STORE_MODULE_NAME, carsModule)
    if (!store.hasModule(this.STATISTICS_MODULE_NAME)) store.registerModule(this.STATISTICS_MODULE_NAME, statisticsModule)
    this.getStatistics()
    this.getYearStatistics()
  },
  methods: {

    changeYear (year) {
      this.current_year = year
      this.getYearStatistics()
    },

    getStatistics() {
      if (this.userType === 'ADMIN' || this.userType === 'VIEWER') {
       // this.getCompaniesCheckups()
        if (this.filters.company) {
          this.getOperatorsStat()
        }
      }
      else {
        this.getOperatorsStat()
      }
      this.getCheckupsStatistics()
      this.getFuelStatistics()
      this.getCarGroupStatistics()
    },

    getOperatorsStat () {
      const filterData = {
        ...this.dateRange,
        ...this.filters
      }
      this.$store.dispatch(`${this.STATISTICS_MODULE_NAME}/getOperatorsStat`, this.serialize(filterData))
    },

    getFuelStatistics () {
      const filterData = {
        ...this.dateRange,
        ...this.filters
      }
      this.$store.dispatch(`${this.STATISTICS_MODULE_NAME}/getFuelStatistics`, this.serialize(filterData))
    },

    getCarGroupStatistics () {
      const filterData = {
        ...this.dateRange,
        ...this.filters
      }
      this.$store.dispatch(`${this.STATISTICS_MODULE_NAME}/getCarGroupStatistics`, this.serialize(filterData))
    },

    getYearStatistics () {
      const ranges = []
      for (let i = 0; i < 12; i++) {
        let start = new Date(this.current_year, i, 1)
        let end = new Date(this.current_year, i + 1, 0)
        end.setHours(23, 59, 59, 59)
        let temp = {
          start,
          end
        }
        ranges.push(temp)
      }
      const filterData = {
        ranges: JSON.stringify(ranges),
        ...this.filters
      }
      this.$store.dispatch(`${this.STATISTICS_MODULE_NAME}/getYearStatistics`, this.serialize(filterData))
    },

    serialize(obj) {
      let str = []
      for (let p in obj) {
        if (obj.hasOwnProperty(p)) {
          if (typeof obj[p] !== 'undefined' && obj[p] !== null && obj[p] !== '') {
            str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]))
          }
        }
      }
      return str.join('&')
    },

    changeCompany() {
      this.getStatistics()
      this.getYearStatistics()
    },

    getCheckupsStatistics() {
      const filterData = {
        ...this.dateRange,
        ...this.filters
      }
      this.$store.dispatch(this.STATISTICS_MODULE_NAME + '/getCheckupsStatistics', this.serialize(filterData))
    },

    getCompaniesCheckups() {
      const filterData = {
        ...this.dateRange,
      }
      this.$store.dispatch(this.STATISTICS_MODULE_NAME + '/getCompaniesCheckups', this.serialize(filterData))
    },

    updateRange() {
      this.dateRange.startDate.setHours(0, 0, 0, 0)
      this.dateRange.endDate.setHours(23, 59, 59, 59)
      this.getStatistics()
    }

  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
