<template>
  <b-card class="chartCard" no-body>
    <b-card-header>
      <div>
        <b-card-sub-title class="mb-25">
          {{ title }}
        </b-card-sub-title>
      </div>
      <!-- datepicker -->
      <!-- datepicker -->
    </b-card-header>

    <!-- chart -->
    <b-card-body>
      <HorizontalBarChart
          v-if="hasHeight"
          :height="height"
          :data="data.data"
          :options="data.options"
      />
      <HorizontalBarChart
          v-else
          :data="data.data"
          :options="data.options"
      />
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardBody, BCardHeader, BCardTitle, BCardSubTitle,
} from 'bootstrap-vue'
import HorizontalBarChart from '@/views/pages/statistics/Charts/components/HorizontalBarChart'

export default {
  components: {
    BCard,
    BCardBody,
    BCardHeader,
    BCardTitle,
    BCardSubTitle,
    HorizontalBarChart,
  },
  props: [
    'data', 'title', 'height'
  ],
  computed: {
    hasHeight () {
      return (this.height > 0)
    }
  },
  data() {
    return {
      rangePicker: ['2019-05-01', '2019-05-10'],
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
